
import { Component, Vue } from "vue-property-decorator";
import { PublicOffice } from "@/store/modules/public-office.store";
import { Action, Getter } from "vuex-class";

@Component
export default class LayoutGuest extends Vue {
    @Getter("public-office/viewing") currentOffice!: PublicOffice;
    @Action("public-office/read") readPublicOffice!: (payload: { office_id?: number; setStyling?: boolean }) => Promise<PublicOffice>;

    async created() {
        const currentOffice = Vue.$cookies.get("hubr-office");
        if (currentOffice) {
            await this.readPublicOffice({ office_id: (currentOffice as PublicOffice).id, setStyling: true });
        }
    }
}
